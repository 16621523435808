import React, { useEffect, useState } from 'react'
import { find, isEmpty } from 'lodash'

import { Modal, Grid, Input, Form, Button, Table, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { get, some } from 'lodash'
import './EditAssetModal.scss'
import AssetPublicPrivate from 'components/assetPublicPrivate/AssetPublicPrivate'
import { useAsset, IAssetTaxonomy, IEditAssetModalFile } from 'store'
import { arrayEquals, findMismatches } from 'utils/array'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFileUpload } from 'hooks'
import { match } from 'utils/validation'
import TaxonomySelector from 'components/taxonomy/TaxonomySelector'
import { useEnv } from '@praxis/component-runtime-env'

export interface IAssetModalValues {
  id: string
  name: string
  description: string
  isPrivate: boolean
  files: IEditAssetModalFile[]
  // TODO: Remove assetTaxonomy and only use data necessary for the modal.
  assetTaxonomy: IAssetTaxonomy
  taxonomyId: string
}

export interface IAssetModalProps {
  data: IAssetModalValues
  closeModal: () => void
}

const EditAssetModal: React.FC<IAssetModalProps> = ({ data, closeModal }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [formDisabled, setFormDisabled] = useState<boolean>(false)
  const [isPublic, setIsPublic] = useState<boolean>(!data.isPrivate)
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<string>('')
  const [selectedTaxonomyTouched, setSelectedTaxonomyTouched] = useState<boolean>(false)
  const [assetTaxonomies, setAssetTaxonomies] = useState<IAssetTaxonomy[]>([])
  const { editAsset, newFilesStatusValues } = useAsset()
  const { isFileDenyListed, validateFileNames } = useFileUpload()
  const { nameMatcher } = useEnv()

  const formatTaxonomies = async (
    data: IAssetTaxonomy,
    currentData: IAssetTaxonomy[] = []
  ): Promise<IAssetTaxonomy[]> => {
    let preselectedTaxonomies: IAssetTaxonomy[] = [...currentData]
    return new Promise(resolve => {
      preselectedTaxonomies.push({ name: data.name, id: data.id })

      if (data.parent) {
        resolve(formatTaxonomies(data.parent, preselectedTaxonomies))
      } else {
        resolve(preselectedTaxonomies)
      }
    })
  }

  const processTaxonomies = async (taxonomies: IAssetTaxonomy) => {
    // Grabbing taxonomies from asset taxonomy, filtering out assets and then reversing so they're in the correct order for Taxonomy Select component.
    const presetTaxonomies = (await formatTaxonomies(taxonomies, []))
      .filter(taxonomy => taxonomy.name !== 'Asset')
      .reverse()
    setAssetTaxonomies(presetTaxonomies)
  }

  useEffect(() => {
    if (data.assetTaxonomy) {
      processTaxonomies(data.assetTaxonomy)
    }
  }, [data.assetTaxonomy])

  const initialValues: IAssetModalValues = {
    id: data.id,
    name: data.name,
    description: data.description,
    isPrivate: data.isPrivate,
    files: data.files,
    assetTaxonomy: data.assetTaxonomy,
    taxonomyId: data.taxonomyId,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: IAssetModalValues) => {
      // current files array and name string of modal
      const { name: newAssetName, files: newAssetFiles } = values
      const {
        id: assetId,
        files: initialAssetFiles,
        name: initialAssetName,
        taxonomyId: initialTaxonomyId,
      } = initialValues
      const initialIsPublic = !initialValues.isPrivate
      const newIsPublic = isPublic
      const newTaxonomyId = selectedTaxonomy
      setIsSubmitting(true)

      // finds discrepencies in file arrays for deleted/added files
      const { newArray1: filesToRemove, newArray2: filesToAdd } = findMismatches(initialAssetFiles, newAssetFiles, 'id')
      editAsset(
        assetId,
        { initialAssetName, newAssetName, initialIsPublic, newIsPublic, initialTaxonomyId, newTaxonomyId },
        filesToAdd,
        filesToRemove,
        onSubmitSuccess
      )
    },
    validate: (values: IAssetModalValues) => {
      const errors: any = {}
      if (!values.name.trim()) {
        errors.name = 'Required'
      }
      if (!match(values.name, nameMatcher.expression)) {
        errors.name = nameMatcher.message
      }
      if (values.files.length <= 0) {
        errors.files = 'Required'
      }

      if (!selectedTaxonomy.trim()) {
        errors.taxonomyId = 'Required'
      }
      return errors
    },
  })

  const onSubmitSuccess = () => {
    closeModal()
  }

  const handleMultipartUpload = async (e: any) => {
    const files = get(e, 'dataTransfer.files') || get(e, 'target.files')

    let assetFiles = Object.keys(files).map((key: string) => ({
      name: files[key].name,
      size: files[key].size,
      type: files[key].type,
      file: files[key],
    }))

    assetFiles.forEach(newFile => {
      const nameAlreadyExists = find(formik.values.files, function (formikFile) {
        return newFile.name === formikFile.name
      })
      if (nameAlreadyExists) {
        const insertIndex = newFile.name?.lastIndexOf('.')
        newFile.name = [newFile.name?.slice(0, insertIndex), '(1)', newFile.name?.slice(insertIndex)].join('')
      }
      let insertNumber = 2
      while (
        find(formik.values.files, function (formikFile) {
          return newFile.name === formikFile.name
        })
      ) {
        const insertIndex = newFile.name?.lastIndexOf(')')
        newFile.name = [
          newFile.name?.slice(0, insertIndex!! - 1),
          `${insertNumber}`,
          newFile.name?.slice(insertIndex),
        ].join('')
      }
    })

    const denyListedFilesExists = await isFileDenyListed(
      assetFiles.map(assetFile => {
        return assetFile.name!!
      })
    )

    const invalidFileNames = await validateFileNames(assetFiles.map(file => file.name))

    if (some(assetFiles) && !denyListedFilesExists && !some(invalidFileNames)) {
      formik.setFieldValue('files', [...formik.values.files, ...assetFiles])
    }
  }

  useEffect(() => {
    formik.validateForm()
  }, [isPublic, formik.values.name, formik.values.files, selectedTaxonomy, selectedTaxonomyTouched])

  useEffect(() => {
    if (!arrayEquals(formik.values.files, initialValues.files)) {
      formik.setFieldTouched('files', true)
    }
  }, [formik.values.files])

  useEffect(() => {
    if (!(formik.values.name === data.name)) {
      formik.setFieldTouched('name', true)
    }
  }, [formik.values.name])

  useEffect(() => {
    const formikInvalid = !formik.isValid
    const publicChanged = isPublic === data.isPrivate
    const formTouched = !isEmpty(formik.touched)
    const formChanged = publicChanged || formTouched
    if (isSubmitting || formikInvalid || !formChanged) {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }
  }, [isSubmitting, formik.isValid, isPublic, formik.touched])

  return (
    <Modal className="modal-parent-container" isVisible onRefuse={() => closeModal()} onApproveModal={() => {}}>
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">Edit Asset</h1>
      </div>
      <div className="hc-pa-normal">
        <form onSubmit={formik.handleSubmit} className="this-form">
          <Grid.Container direction="column">
            <Grid.Item xs={12}>
              <Grid.Container direction="row">
                <Grid.Item xs={6}>
                  <Grid.Container className="hc-pa-normal">
                    <Grid.Item xs={12} className="hc-pb-none">
                      <Form.Field
                        error={!!formik.errors.name}
                        errorText={formik.errors.name}
                        type="text"
                        name="name"
                        placeholder="Asset name"
                        label="Asset name*"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        data-testid="edit-asset-name-input"
                        className="hc-mb-dense"
                      />
                    </Grid.Item>
                    <Grid.Item xs={12} className="hc-pt-none">
                      {assetTaxonomies.length > 0 && (
                        <TaxonomySelector
                          key={'taxonomy-selector'}
                          defaultSelections={assetTaxonomies}
                          setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
                          setSelectedTaxonomy={setSelectedTaxonomy}
                          setTaxonomyTouched={() => {
                            formik.setFieldTouched('assetTaxonomy', true)
                          }}
                        />
                      )}
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Grid.Container className="hc-pa-normal">
                    <Grid.Item xs={12}>
                      <Input.Label
                        error={!!(formik.touched.files && formik.errors.files)}
                        className="hc-mb-none hc-pb-sm bold-asset-text"
                      >
                        Files in asset ({formik.values.files.length})
                      </Input.Label>
                      <Grid.Container direction="column">
                        {some(formik.values.files) && (
                          <Grid.Item xs={12} className="hc-pt-dense">
                            <div className="edit-asset-fileList" data-testid="edit-asset-file-list">
                              <Table>
                                <Table.Body>
                                  {formik.values.files.map((file, index) => (
                                    <Table.Row key={index} align="center">
                                      <Table.Data
                                        xs={9}
                                        className="hc-pl-none asset-file"
                                        data-testid={`file_ready_for_upload_${file.id}`}
                                      >
                                        {file.name}
                                      </Table.Data>
                                      <Table.Data xs={3} className="hc-ta-right">
                                        {!newFilesStatusValues.find(fileValue => fileValue.name === file.name) && (
                                          <Button
                                            iconOnly
                                            size="dense"
                                            data-testid={`remove_file_${file.id}`}
                                            onClick={() => {
                                              const newFiles = [...formik.values.files]
                                              newFiles.splice(index, 1)
                                              formik.setFieldValue('files', newFiles)
                                            }}
                                          >
                                            <FontAwesomeIcon icon="trash" className="trash-icon" />
                                          </Button>
                                        )}

                                        {newFilesStatusValues.find(
                                          fileValue => fileValue.name === file.name && fileValue.status === 'UPLOADING'
                                        ) && <Spinner size="dense" />}
                                        {newFilesStatusValues.find(
                                          fileValue => fileValue.name === file.name && fileValue.status === 'UPLOADED'
                                        ) && <FontAwesomeIcon icon="check" color="green" size="lg" />}
                                        {newFilesStatusValues.find(
                                          fileValue => fileValue.name === file.name && fileValue.status === 'QUEUED'
                                        ) && <FontAwesomeIcon icon="clock" size="lg" />}
                                      </Table.Data>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                              </Table>
                            </div>
                          </Grid.Item>
                        )}
                        <Grid.Item xs={12} className="hc-pt-dense">
                          <Input.DropArea
                            data-testid="asset-file-upload"
                            expanded
                            multiple
                            fullwidth
                            instructionText={'Drop files or click here to upload'}
                            onUpdate={(e: any) => handleMultipartUpload(e)}
                          />
                        </Grid.Item>
                        <Grid.Item xs={12}>
                          <AssetPublicPrivate makeAssetPublic={setIsPublic} assetPublic={isPublic} />
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container direction="row" justify="flex-end" align="center" className="hc-pa-normal">
                <Button type="secondary" data-testid="edit-asset-cancel" onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="submit-button"
                  onClick={() => formik.submitForm()}
                  disabled={formDisabled}
                  data-testid="edit-asset-submit"
                >
                  {isSubmitting && <Spinner size="dense" />}
                  {!isSubmitting && 'Save'}
                </Button>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </form>
      </div>
    </Modal>
  )
}

export default EditAssetModal
