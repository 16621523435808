import React, { useState, useContext } from 'react'
import { Anchor, Button, Caption, Card, Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MinusCircleIcon } from '@enterprise-ui/icons'
import getStatusIconAndColor from './Utils/getStatusIconAndColor'
import { bulkAssetProgressStatuses, bulkAssetProgressStatusesMap } from './Utils/constants'
import removeBulkUploadMessageFromLS from './Utils/removeBulkUploadMessageFromLS'
import { BulkAssetDataContext } from '../BaseLayout'
import { useNavigate } from 'react-router-dom'

const BulkAssetUploadItem = ({ assetData }: any) => {
  const navigate = useNavigate()
  const { loadLSData, setLoadLSData } = useContext(BulkAssetDataContext)
  const [showDetails, setShowDetails] = useState(false)
  const { icon, color } = getStatusIconAndColor(assetData.progress)
  const progressMessage =
    bulkAssetProgressStatuses[assetData.progress] || bulkAssetProgressStatuses[bulkAssetProgressStatusesMap.INPROGRESS]
  return (
    <Card className="bulk-asset-upload-popup-item hc-pa-normal">
      <Grid.Container align="center" justify="space-between">
        <Grid.Item xs={8}>
          <Anchor
            fullWidth
            onClick={() => {
              setShowDetails(!showDetails)
            }}
            tabIndex="-1"
          >
            {assetData.assetName}
          </Anchor>
        </Grid.Item>
        <Grid.Item>
          {loadLSData && (
            <Tooltip content={`Asset Creation status: ${progressMessage}`} location="top">
              <EnterpriseIcon data-testid="progress-icon" icon={icon} className={color} />
            </Tooltip>
          )}
          <Tooltip content={`Remove message`} location="top">
            <Button
              iconOnly
              type="ghost"
              onClick={() => {
                removeBulkUploadMessageFromLS(assetData.id)
                setLoadLSData(false)
              }}
            >
              <EnterpriseIcon icon={MinusCircleIcon} className="hc-clr-error" />
            </Button>
          </Tooltip>
        </Grid.Item>
        {showDetails && loadLSData && (
          <div className="hc-pa-normal">
            <Caption above={'Progress'}>{progressMessage}</Caption>
            {assetData.assetId && (
              <Caption above={'Asset Id'} className="hc-pt-normal">
                <Tooltip content="Go to asset details page" location="top">
                  <Anchor
                    onClick={() => {
                      navigate(`/asset/${assetData.assetId}`)
                    }}
                  >
                    {assetData.assetId}
                  </Anchor>
                </Tooltip>
              </Caption>
            )}
          </div>
        )}
      </Grid.Container>
    </Card>
  )
}

export default BulkAssetUploadItem
