export const bulkAssetProgressStatusesMap = {
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
}

export const bulkAssetProgressStatuses = {
  [bulkAssetProgressStatusesMap.INPROGRESS]: 'In Progress',
  [bulkAssetProgressStatusesMap.COMPLETED]: 'Completed',
  [bulkAssetProgressStatusesMap.FAILED]: 'Failed',
}

export const BulkAssetUploadLsName = 'BulkAssetUpload'
