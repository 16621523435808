import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { includesCaseInsensitive } from 'utils/string'
import EnterpriseIcon, { PlusCircleIcon, ChevronDownIcon, CaretDownIcon } from '@enterprise-ui/icons'

import { Button, Chip, Dropdown, Grid, Heading, Modal, Spinner, Tabs, useToaster } from '@enterprise-ui/canvas-ui-react'
import { useLocation, useParams } from 'react-router-dom'
import Classnames from 'classnames'
import { MODAL_TYPES } from 'config/constants'
import BasePage from 'components/pages/BasePage/BasePage'
import FileTable from './FileTable'
import { IDeleteModalData } from 'components/modals/DeleteModal/DeleteModal'
import { IAssetModalValues } from 'components/modals/EditAssetModal/EditAssetModal'
import styles from './AssetDetailPage.module.scss'
import { IEditAssetManagerModalValues } from 'components/modals/EditAssetManagerModal/EditAssetManagerModal'
import { IAssetActivityValues } from 'components/modals/AssetActivityModal/AssetActivityModal'
import { useElementSize } from 'hooks'
import DetailView from './DetailView/DetailView'
import TagView from './TagView/TagView'
import {
  BreadCrumbPriority,
  IAsset,
  IBreadCrumb,
  ILocationState,
  PageStatus,
  removeCrumb,
  useAsset,
  useAuth,
  useBreadCrumbs,
  useFiles,
  useModal,
} from 'store'
import LoadingState from 'components/shared/LoadingState'
import ErrorState from 'components/shared/ErrorState'
import { useEnv } from '@praxis/component-runtime-env'
import LinkedAssetTable from './LinkedAssets/LinkedAssetTable'
import { useDispatch } from 'react-redux'

// Lazy loading MediaView Component to decouple it's dependencies from our main js bundle.
const MediaViewer = lazy(() => import('./MediaViewer/MediaViewer'))

const TAB_NAMES = {
  FILES: 'files',
  DETAILS: 'details',
  LINKED_ASSETS: 'linked assets',
}

const AssetDetailPage = () => {
  const { id } = useParams()
  const { userSpecialPermissions } = useEnv()
  const location = useLocation()
  const mediaViewerContainer = useRef(null)
  const { width: containerWidth } = useElementSize(mediaViewerContainer)
  const { crumbsFromSlice } = useBreadCrumbs()
  const dispatch = useDispatch()
  const makeToast = useToaster()

  const [activeTab, setActiveTab] = useState<string>(TAB_NAMES.DETAILS)
  const {
    getAsset,
    setAsset: setAssetInStore,
    deleteAsset,
    assetDetailPageAsset: asset,
    assetDetailPageStatus: pageStatus,
    setAssetDetailStatus,
    userAssetPermissions,
    downloadAssetLocally,
    downloadAsset,
    downloadLinkedAssets,
    sendAssetToGD,
    getAssetStatus,
    process3DFile,
  } = useAsset()
  const { resetFileStore, initializeCheckedFiles, selectAll, downloadMultipleFiles, checkedFileIds } = useFiles()
  const { saveDataAndTypeToStore } = useModal()
  const { canUserCreateContent, isAdmin, userInfo } = useAuth()
  const isUserCreator = canUserCreateContent()
  const isUserAdmin = isAdmin()
  const [assetTitle, setAssetTitle] = useState<string>('')
  const [assetStatus, setAssetStatus] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [crumb, setCrumb] = useState<IBreadCrumb | {}>({})
  const locationState = location.state as unknown as ILocationState

  // Clears any other asset pages from breadcrumbs
  // TODO: Decide if this fix should be on a per page basis or if we should implement this app wide.
  useEffect(() => {
    crumbsFromSlice?.forEach(crumb => {
      if (includesCaseInsensitive(crumb.href, '/asset/') && crumb.href !== location.pathname && asset) {
        if (locationState?.state?.isLinkedAsset) dispatch(removeCrumb(crumb.name))
      }
    })
    if (asset) {
      setAssetTitle(asset.name)
      setCrumb({ name: asset.name, href: '', priority: BreadCrumbPriority.Low })
      initializeCheckedFiles(asset.files)
      setAssetStatus(getAssetStatus(asset.assetTags, asset.taxonomyId))
    }
  }, [asset])

  // fetches asset on component loading from url params
  useEffect(() => {
    if (id) {
      getAsset(id)
    }
    // resets assetState so old asset wont show on new page
    return () => {
      setAssetInStore(undefined)
      resetFileStore()
      setAssetDetailStatus(PageStatus.idle)
      trackAndSetActiveTab(TAB_NAMES.DETAILS)
    }
  }, [id])

  const trackAndSetActiveTab = (tab: string) => {
    ReactGA.event({
      category: 'Asset Detail',
      action: 'Tab Clicked',
      label: tab,
    })

    if (tab !== 'more') setActiveTab(tab)
  }

  const errorState = () => {
    return <ErrorState name={'Asset'} />
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const confirmModal = () => {
    const assetId = asset?.id ? [asset.id] : []
    process3DFile(assetId)
    makeToast({
      heading: 'Re-processing has begun',
      message: '',
    })
    closeModal()
  }

  const assetDetailPage = () => {
    return (
      <BasePage crumbs={crumb}>
        <Grid.Container
          direction="column"
          className={`${styles['content-container']} hc-pa-lg`}
          data-testid="content-container"
        >
          <Grid.Item>
            <Grid.Container direction="row" align="center">
              <Grid.Item xs={12} lg={4} className="hc-pb-dense">
                <Grid.Container direction="row">
                  <Grid.Item className={'hc-pr-md'}>
                    <Heading data-testid="title">{assetTitle}</Heading>
                  </Grid.Item>
                  {assetStatus !== '' && (
                    <Grid.Item className={'hc-pl-none'}>
                      <Chip
                        data-testid={`asset-status-chip-${assetStatus}`}
                        size={'dense'}
                        color={assetStatus === 'Active' ? 'success' : 'error'}
                      >
                        {assetStatus}
                      </Chip>
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xs={12} lg={8}>
                <Grid.Container direction="row" justify="flex-end" align="center">
                  {isUserCreator && (
                    <Grid.Item className="hc-pr-none">
                      <Button
                        type="secondary"
                        className="hc-mr-dense"
                        data-testid="add-to-collection-button"
                        onClick={() => {
                          saveDataAndTypeToStore(MODAL_TYPES.ADD_COLLECTION, [asset])
                        }}
                      >
                        <EnterpriseIcon icon={PlusCircleIcon} className="hc-pr-dense" />
                        Add To Collection
                      </Button>
                    </Grid.Item>
                  )}
                  <Grid.Item className="hc-pl-none">
                    <Dropdown location="bottom-right" style={{ zIndex: 9999 }}>
                      <Button type="primary" data-testid="asset-download-dropdown-button">
                        Download <EnterpriseIcon className="hc-pl-dense" icon={ChevronDownIcon} />
                      </Button>
                      <Dropdown.Menu>
                        {selectAll.status === 0 || selectAll.status === 1 ? (
                          <Dropdown.MenuItem
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) => downloadAssetLocally(e, asset!!.id)}
                            data-testid="asset-download-button-full"
                          >
                            Download all files
                          </Dropdown.MenuItem>
                        ) : (
                          <Dropdown.MenuItem
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                              downloadMultipleFiles(asset!!.id, checkedFileIds())
                            }
                            data-testid="asset-download-button-partial"
                          >
                            Download selected files
                          </Dropdown.MenuItem>
                        )}
                        {!!asset?.linkedAssets && (
                          <Dropdown.MenuItem
                            data-testid="asset-download-dropdown-asset-and-linked-assets"
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                              downloadLinkedAssets(id!)
                            }}
                          >
                            This and all linked assets
                          </Dropdown.MenuItem>
                        )}

                        <Dropdown.MenuItem
                          data-testid="asset-download-dropdown-primary-asset-only"
                          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                            downloadAsset(id!)
                          }}
                        >
                          This asset only
                        </Dropdown.MenuItem>
                        {selectAll.status === 0 || selectAll.status === 1 ? (
                          <Dropdown.MenuItem
                            data-testid="asset-download-dropdown-local-download-full"
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) => downloadAssetLocally(e, asset!!.id)}
                          >
                            Download asset locally
                          </Dropdown.MenuItem>
                        ) : (
                          <Dropdown.MenuItem
                            data-testid="asset-download-dropdown-local-download-partial"
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
                              downloadMultipleFiles(asset!!.id, checkedFileIds())
                            }
                          >
                            Download locally
                          </Dropdown.MenuItem>
                        )}
                        {userInfo?.specialPermissions?.includes(userSpecialPermissions?.galleryDesktop?.view) && (
                          <Dropdown.MenuItem
                            data-testid="asset-download-dropdown-send-to-GD"
                            onClick={() => sendAssetToGD(asset!!.id)}
                          >
                            Send asset to Gallery Desktop
                          </Dropdown.MenuItem>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <div className="hc-pa-normal hc-pa-none">
            <Grid.Item xs={12}>
              <Grid.Container direction="row">
                <Grid.Item xs={12} sm={12} md={12} lg={6} ref={mediaViewerContainer}>
                  {/*MEDIAVIEWER*/}
                  {/* Ref here is to calculate viewer width (containerWidth - padding) for responsive sizing, ref doesn't update on MediaViewer component */}
                  <Grid.Container justify="center" className="hc-pt-xl">
                    <Suspense fallback={<Spinner />}>
                      {
                        <MediaViewer
                          files={asset!!.files}
                          // This edge case should be taken care of with max content width of detail page
                          viewerWidth={containerWidth - 24 < 624 ? containerWidth - 24 : 624}
                        />
                      }
                    </Suspense>
                  </Grid.Container>
                </Grid.Item>
                <Grid.Item xs={12} sm={12} md={12} lg={6}>
                  {/*Details Section*/}
                  <Grid.Container direction="column" justify="center" align="flex-end">
                    <Grid.Item xs={12} className="hc-pt-expanded hc-pr-lg hc-mr-min">
                      <Tabs
                        size="expanded"
                        disableAdaptive
                        activeTab={activeTab}
                        onTabSelect={(_e: any, tab: any) => trackAndSetActiveTab(tab.name)}
                        className={styles['tabs-selector']}
                      >
                        <Tabs.Item name={TAB_NAMES.DETAILS} data-testid="tab-details">
                          Details
                        </Tabs.Item>
                        <Tabs.Item name={TAB_NAMES.FILES} data-testid="tab-files" className={styles['tab-selector']}>
                          Files
                        </Tabs.Item>
                        {!!asset?.linkedAssets && (
                          <Tabs.Item
                            name={TAB_NAMES.LINKED_ASSETS}
                            data-testid="tab-linked-assets"
                            className={styles['tab-selector']}
                          >
                            Linked assets
                          </Tabs.Item>
                        )}
                        <Tabs.Item name="more" className="hc-pa-none">
                          <Dropdown size="dense" location="bottom-right" className={`${styles['more-dropdown-menu']}`}>
                            <div className="hc-pl-expanded hc-pt-normal hc-pb-expanded hc-pr-lg">
                              <span>More</span>
                              <EnterpriseIcon
                                icon={CaretDownIcon}
                                className={`hc-mb-dense ${styles['more-tab-arrow']}`}
                              />
                            </div>
                            <Dropdown.Menu>
                              {(isUserAdmin ||
                                userAssetPermissions.userCanEditAndDeleteAsset ||
                                userAssetPermissions.userIsAssetManager) && (
                                <>
                                  <Dropdown.MenuItem
                                    data-testid="drop-down-item-add-to-collection"
                                    onClick={() => saveDataAndTypeToStore(MODAL_TYPES.ADD_COLLECTION, [asset])}
                                  >
                                    Add to Collection
                                  </Dropdown.MenuItem>
                                  <Dropdown.MenuItem
                                    data-testid="drop-down-item-edit-asset"
                                    onClick={(e: Event) => {
                                      e.preventDefault()

                                      const findIsAssetPrivate = (asset: IAsset) => {
                                        const belongsToPublicOrg =
                                          asset.assetOrganizations.filter(
                                            organization =>
                                              organization.organization?.id === 'cccccccc-aaaa-0000-ffff-000000000001'
                                          ).length > 0
                                        return !belongsToPublicOrg
                                      }

                                      const modalData: IAssetModalValues = {
                                        id: asset!!.id,
                                        name: asset!!.name,
                                        description: '',
                                        isPrivate: findIsAssetPrivate(asset!!),
                                        files: asset!!.files,
                                        assetTaxonomy: asset!!.assetTaxonomy,
                                        taxonomyId: asset!!.assetTaxonomy.id,
                                      }
                                      saveDataAndTypeToStore(MODAL_TYPES.EDIT_ASSET, modalData)
                                    }}
                                  >
                                    Edit asset
                                  </Dropdown.MenuItem>
                                  <Dropdown.MenuItem
                                    data-testid="drop-down-item-edit-asset-manager"
                                    onClick={(e: Event) => {
                                      const modalData: IEditAssetManagerModalValues = {
                                        assetId: asset!.id!!,
                                        user_to_be_added: '',
                                        user_to_be_removed: '',
                                      }
                                      saveDataAndTypeToStore(MODAL_TYPES.EDIT_ASSET_USER, modalData)
                                    }}
                                  >
                                    Edit asset manager
                                  </Dropdown.MenuItem>
                                </>
                              )}
                              <Dropdown.MenuItem
                                data-testid="drop-down-item-re-process-3d-file"
                                onClick={() => setIsModalOpen(true)}
                              >
                                Re-process 3D file
                              </Dropdown.MenuItem>
                              <Dropdown.MenuItem
                                data-testid="drop-down-item-view-activity-log"
                                onClick={() => {
                                  const modalData: IAssetActivityValues = {
                                    assetId: asset!.id,
                                  }
                                  saveDataAndTypeToStore(MODAL_TYPES.ASSET_ACTIVITY, modalData)
                                }}
                              >
                                View activity log
                              </Dropdown.MenuItem>
                              {(isUserAdmin ||
                                userAssetPermissions.userCanEditAndDeleteAsset ||
                                userAssetPermissions.userIsAssetManager) && (
                                <Dropdown.MenuItem
                                  data-testid="drop-down-item-delete-asset"
                                  onClick={(e: Event) => {
                                    e.preventDefault()
                                    const modalData: IDeleteModalData = {
                                      data: asset!.id,
                                      triggerDelete: () => deleteAsset(asset!!.id),
                                      bodyText: `"${assetTitle}" will be permanently deleted from Gallery.`,
                                      onRefuseText: 'Cancel',
                                      onApproveText: 'Delete Asset',
                                      headingText: 'Delete this asset?',
                                    }
                                    saveDataAndTypeToStore(MODAL_TYPES.DELETE_ASSET, modalData)
                                  }}
                                >
                                  Delete asset
                                </Dropdown.MenuItem>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Tabs.Item>
                      </Tabs>
                    </Grid.Item>
                    <Grid.Item
                      xs={12}
                      data-testid="tabs-content-files"
                      className={`${styles['data-container']} hc-pt-none ${Classnames(styles['tabs__content'], {
                        [styles.hidden]: activeTab !== TAB_NAMES.FILES,
                      })}`}
                    >
                      <FileTable key={asset!!.id} />
                    </Grid.Item>
                    <Grid.Item
                      xs={12}
                      data-testid="tabs-content-details"
                      className={`${styles['data-container']} ${Classnames(styles['tabs__content'], {
                        [styles.hidden]: activeTab !== TAB_NAMES.DETAILS,
                      })}`}
                    >
                      <Grid.Container direction="column">
                        <Grid.Item>
                          <DetailView assetId={asset!!.id} />
                        </Grid.Item>
                        <Grid.Item>
                          <div
                            className="hc-pa-normal hc-pt-none"
                            data-testid="empty-detail-drawers custom-detail-tags"
                          >
                            <TagView assetId={asset!!.id} />
                          </div>
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                    {!!asset?.linkedAssets && (
                      <Grid.Item
                        xs={12}
                        data-testid="tabs-content-linked-assets"
                        className={`${styles['data-container']} ${Classnames(styles['tabs__content'], {
                          [styles.hidden]: activeTab !== TAB_NAMES.LINKED_ASSETS,
                        })}`}
                      >
                        <LinkedAssetTable />
                      </Grid.Item>
                    )}
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </div>
        </Grid.Container>
        <Modal
          isVisible={isModalOpen}
          size="dense"
          headingText="Re-process 3D file"
          data-testid="re-process-confirmation-modal"
          onRefuse={() => closeModal()}
        >
          <div className="hc-pa-normal">
            <Grid.Container>
              <Grid.Item xs>
                <p>It can take up to several hours to reprocess a file, but will not affect your use of Gallery.</p>
              </Grid.Item>
            </Grid.Container>
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item>
                <Button
                  data-testid="re-process-confirmation-modal-confirm"
                  onClick={() => confirmModal()}
                  type="primary"
                >
                  Confirm
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  data-testid="re-process-confirmation-modal-cancel"
                  onClick={() => closeModal()}
                  type="secondary"
                >
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      </BasePage>
    )
  }

  // TODO: Determine how we can replace this with React.Suspense
  switch (pageStatus) {
    case PageStatus.idle:
    case PageStatus.loading:
      return <LoadingState crumb={crumb} />
    case PageStatus.error:
      return errorState()
    case PageStatus.success:
      return !asset ? errorState() : assetDetailPage()
  }
}

export default AssetDetailPage
