import { STORAGE_KEYS } from 'config'
import {
  bulkAssetProgressStatusesMap,
  BulkAssetUploadLsName,
} from 'containers/BaseLayout/BulkAssetUploadPopup/Utils/constants'
import { cloneDeep } from 'lodash'
import { IAsset, ICreateAssetFileValues, processError } from 'store'
import { addFileToAssetApi, createAssetApi } from 'store/asset/api'
import { v4 as uuidv4 } from 'uuid'

export interface bulkAssetUploadLsData {
  id: string
  assetName: string
  assetId: string | null
  progress: string
}

const createIndividualAssetApi = async (data: any) => {
  // clear previous upload status data
  const { name, files, isAssetPublic, taxonomyId, onSuccessCallback, onErrorCallback } = data
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  let assetFile: ICreateAssetFileValues = files[0]
  let filesToUpload: ICreateAssetFileValues[] = []
  let startPipelineOnAssetCreate = 'true'

  if (files.length > 1) {
    startPipelineOnAssetCreate = 'false'
    filesToUpload = files.slice(1)
  }

  try {
    // calls createApi with first file
    const asset: IAsset = await createAssetApi(
      xTgtLanId,
      name,
      assetFile.file,
      isAssetPublic,
      taxonomyId,
      'NONE',
      startPipelineOnAssetCreate
    )
    // uploads subsequent files
    if (filesToUpload.length) {
      await Promise.all(
        filesToUpload.map((file, idx) => {
          return addFileToAssetApi(
            xTgtLanId,
            asset.id,
            file.file,
            file.name,
            idx === filesToUpload.length - 1 ? true : false
          )
        })
      )
    }
    if (typeof onSuccessCallback === 'function') {
      onSuccessCallback(asset.id)
    }
  } catch (error) {
    if (typeof onErrorCallback === 'function') {
      onErrorCallback()
    }
    if (error instanceof Error) {
      processError(error, error.message)
    } else {
      console.log(error)
    }
  }
}

const updateBulkAssetLocalStorageData = (uuid: string, progress: string, assetId: string | null, assetName: string) => {
  const bulkAssetUploadData = JSON.parse(localStorage.getItem(BulkAssetUploadLsName) || '[]')
  let newBulkAssetUploadData = cloneDeep(bulkAssetUploadData || [])
  if (!bulkAssetUploadData.find((data: bulkAssetUploadLsData) => data.id === uuid)) {
    newBulkAssetUploadData.push({
      id: uuid,
      assetName,
      assetId: null,
      progress: bulkAssetProgressStatusesMap.INPROGRESS,
    })
  } else {
    newBulkAssetUploadData = bulkAssetUploadData.map((data: bulkAssetUploadLsData) => {
      if (data.id === uuid) {
        return {
          ...data,
          assetName,
          assetId,
          progress,
        }
      }
      return data
    })
  }
  localStorage.setItem(BulkAssetUploadLsName, JSON.stringify(newBulkAssetUploadData))
}

const bulkAssetUploadOnSave = async (
  bulkAssetCreatePayload: {
    name: string
    isAssetPublic: boolean
    taxonomyId: string
    files: ICreateAssetFileValues[]
  }[] = [],
  setShowPageReloadAlert: Function,
  refreshLSData: Function
) => {
  Promise.all(
    bulkAssetCreatePayload.map(payload => {
      const uuid = uuidv4()
      const { name, isAssetPublic, taxonomyId, files } = payload
      updateBulkAssetLocalStorageData(uuid, bulkAssetProgressStatusesMap.INPROGRESS, null, name)
      refreshLSData(false)
      createIndividualAssetApi({
        name,
        files,
        isAssetPublic,
        taxonomyId,
        onSuccessCallback: (newAssetId: string) => {
          updateBulkAssetLocalStorageData(uuid, bulkAssetProgressStatusesMap.COMPLETED, newAssetId, name)
          refreshLSData(false)
        },
        onErrorCallback: () => {
          updateBulkAssetLocalStorageData(uuid, bulkAssetProgressStatusesMap.FAILED, null, name)
          refreshLSData(false)
        },
      })
      return uuid
    })
  )
  setShowPageReloadAlert(true)
}

export default bulkAssetUploadOnSave
