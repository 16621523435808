import React, { useState, useEffect } from 'react'

import { Modal, Grid, Input, Form, Button, Table, Spinner } from '@enterprise-ui/canvas-ui-react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useFormik } from 'formik'
import { get, some } from 'lodash'
import './CreateAssetModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TaxonomySelector from 'components/taxonomy/TaxonomySelector'
import AssetPublicPrivate from 'components/assetPublicPrivate/AssetPublicPrivate'
import { useAsset, ICreateAssetFileValues, IAssetTaxonomy } from 'store'
import { useFileUpload } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { match } from 'utils/validation'
import { useEnv } from '@praxis/component-runtime-env'

interface ICreateAssetValues {
  name: string
  files: ICreateAssetFileValues[]
  taxonomyId: string
}

const CreateAssetModal = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<string>('')
  const [selectedTaxonomyTouched, setSelectedTaxonomyTouched] = useState<boolean>(false)
  const [isAssetPublic, makeAssetPublic] = useState<boolean>(true)
  const [category] = useQueryParam('category', StringParam)
  const { isFileDenyListed, validateFileNames } = useFileUpload()
  const navigate = useNavigate()
  const { nameMatcher } = useEnv()
  const initialValues: ICreateAssetValues = {
    name: '',
    files: [],
    taxonomyId: '',
  }
  const { createAsset, newFilesStatusValues } = useAsset()

  useEffect(() => {
    formik.validateForm()
  }, [selectedTaxonomy, selectedTaxonomyTouched])

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: ICreateAssetValues) => {
      const { name, files } = values
      const taxonomyId = selectedTaxonomy
      setIsSubmitting(true)
      createAsset(name, files, isAssetPublic, taxonomyId, onSuccessCallback, onErrorCallback)
    },
    validate: (values: ICreateAssetValues) => {
      const errors: any = {}
      if (!values.name.trim()) {
        errors.name = 'Required'
      }
      if (!match(values.name, nameMatcher.expression)) {
        errors.name = nameMatcher.message
      }
      if (values.files.length <= 0) {
        errors.files = 'Required'
      }

      if (!selectedTaxonomy.trim()) {
        errors.taxonomyId = 'Required'
      }

      return errors
    },
  })

  const onSuccessCallback = (assetId: string) => {
    navigate(`/asset/${assetId}`, { replace: true })
  }

  const onErrorCallback = () => {
    setIsSubmitting(false)
  }

  const handleMultipartUpload = async (e: any) => {
    const files = get(e, 'dataTransfer.files') || get(e, 'target.files')

    let assetFiles: ICreateAssetFileValues[] = Object.keys(files).map((key: string) => ({
      name: files[key].name,
      file: files[key],
      status: 'QUEUED',
    }))

    const denyListedFileTypesExist = await isFileDenyListed(
      assetFiles.map((assetFile: ICreateAssetFileValues) => {
        return assetFile.name
      })
    )

    const invalidFileNames = await validateFileNames(assetFiles.map(file => file.name))

    if (some(assetFiles) && !denyListedFileTypesExist && !some(invalidFileNames)) {
      formik.setFieldValue('files', [...formik.values.files, ...assetFiles])
    }
  }

  return (
    <Modal className="modal-parent-container" isVisible onRefuse={() => props.closeModal()} onApproveModal={() => {}}>
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">Add Asset</h1>
      </div>
      <div className="hc-pa-normal">
        <form onSubmit={formik.handleSubmit}>
          <Grid.Container direction="column">
            <Grid.Item className="hc-pb-none" xs={12}>
              <Grid.Container direction="row" className="hc-pa-normal">
                <Grid.Item xs={6}>
                  <Grid.Container direction="column" spacing={'dense'}>
                    <Grid.Item xs={12} className="hc-pb-normal">
                      <Form.Field
                        error={!!(formik.touched.name && formik.errors.name)}
                        errorText={formik.errors.name}
                        type="text"
                        name="name"
                        placeholder="Asset name"
                        label="Asset name*"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        data-testid="create-asset-name-input"
                        className="hc-mb-dense"
                      />
                    </Grid.Item>
                    <Grid.Item xs={12} className="hc-pt-none">
                      <TaxonomySelector
                        key={`taxonomy-selector`}
                        setSelectedTaxonomy={setSelectedTaxonomy}
                        setTaxonomyTouched={() => {
                          formik.setFieldTouched('assetTaxonomy', true)
                        }}
                        setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
                        defaultSelections={
                          category ? [{ name: category, id: 0 } as unknown as IAssetTaxonomy] : undefined
                        }
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Grid.Container direction="column">
                    <Grid.Item xs={12}>
                      <Input.Label
                        error={!!(formik.touched.files && formik.errors.files)}
                        className="hc-mb-none hc-pb-sm bold-asset-text"
                      >
                        Files being added ({formik.values.files.length})
                      </Input.Label>
                      <Grid.Container direction="column">
                        {some(formik.values.files) && (
                          <Grid.Item xs={12} className="hc-pt-dense">
                            <div className="edit-asset-fileList" data-testid="create-asset-file-list">
                              <Table>
                                <Table.Body>
                                  {formik.values.files.map((file, index) => (
                                    <Table.Row key={file.name} align="center">
                                      <Table.Data xs={9} className="hc-pl-none" data-test-id="file-ready-for-upload">
                                        {file.name}
                                      </Table.Data>
                                      <Table.Data xs={3} className="hc-ta-right">
                                        {!newFilesStatusValues.find(fileValue => fileValue.name === file.name) && (
                                          <Button
                                            iconOnly
                                            size="dense"
                                            data-test-id="remove-file-button"
                                            onClick={() => {
                                              const newFiles = [...formik.values.files]
                                              newFiles.splice(index, 1)
                                              formik.setFieldValue('files', newFiles)
                                            }}
                                          >
                                            <FontAwesomeIcon icon="trash" className="trash-icon" />
                                          </Button>
                                        )}

                                        {newFilesStatusValues.find(
                                          fileValue => fileValue.name === file.name && fileValue.status === 'UPLOADING'
                                        ) && <Spinner size="dense" />}
                                        {newFilesStatusValues.find(
                                          fileValue => fileValue.name === file.name && fileValue.status === 'UPLOADED'
                                        ) && <FontAwesomeIcon icon="check" color="green" size="lg" />}
                                        {newFilesStatusValues.find(
                                          fileValue => fileValue.name === file.name && fileValue.status === 'QUEUED'
                                        ) && <FontAwesomeIcon icon="clock" size="lg" />}
                                      </Table.Data>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                              </Table>
                            </div>
                          </Grid.Item>
                        )}
                        <Grid.Item xs={12} className="hc-pt-dense container-drop">
                          <Input.DropArea
                            data-testid="asset-file-upload"
                            expanded
                            multiple
                            fullwidth
                            instructionText={'Drop files or click here to upload'}
                            onUpdate={(e: any) => handleMultipartUpload(e)}
                          />
                        </Grid.Item>
                        <Grid.Item xs={12}>
                          <AssetPublicPrivate makeAssetPublic={makeAssetPublic} assetPublic={isAssetPublic} />
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pt-none">
              <Grid.Container direction="row" justify="flex-end" align="center" className="hc-pa-normal">
                <Button type="secondary" data-testid="create-asset-cancel" onClick={() => props.closeModal()}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="submit-button"
                  onClick={() => formik.submitForm()}
                  disabled={isSubmitting || (!formik.isValid && !!formik.touched)}
                  data-testid="create-asset-submit"
                >
                  {isSubmitting && <Spinner size="dense" />}
                  {!isSubmitting && 'Save'}
                </Button>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </form>
      </div>
    </Modal>
  )
}

export default CreateAssetModal
