import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'

export const findTaxonomyPath = (
  taxonomy: TaxonomyNodeView,
  taxonomyId?: string,
  path: string[] = []
): string[] | null => {
  // Add the current node to the path
  path.push(taxonomy.id)
  // Check if the current node matches the taxonomyId
  if (taxonomy.id === taxonomyId) {
    return path
  }
  // Recursively search the children
  if (taxonomy.children && taxonomy.children.length > 0) {
    for (const child of taxonomy.children) {
      const result = findTaxonomyPath(child, taxonomyId, [...path])
      if (result) {
        return result
      }
    }
  }
  // If no match is found in this branch, return null
  return null
}
