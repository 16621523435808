import React, { useState, useContext, useEffect } from 'react'
import { Button, Caption, Card, Grid, List, Popover, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon, InProgressFilledIcon, MinusCircleIcon } from '@enterprise-ui/icons'
import BulkAssetUploadItem from './BulkAssetUploadItem'
import { bulkAssetProgressStatusesMap, BulkAssetUploadLsName } from './Utils/constants'
import { BulkAssetDataContext } from '../BaseLayout'
import './BulkAssetUploadPopup.scss'

const BulkAssetUploadPopup = ({
  showPageReloadAlert,
  setShowPageReloadAlert,
}: {
  showPageReloadAlert: boolean
  setShowPageReloadAlert: Function
}) => {
  const { setLoadLSData } = useContext(BulkAssetDataContext)
  const [showPopUp, setShowPopUp] = useState(false)
  const bulkAssetUploadData = JSON.parse(localStorage.getItem(BulkAssetUploadLsName) || '[]')
  const isAssetCreateInProgress =
    bulkAssetUploadData.filter((data: any) => data.progress === bulkAssetProgressStatusesMap.INPROGRESS).length > 0

  useEffect(() => {
    let timer: any
    if (showPageReloadAlert) {
      timer = setTimeout(() => {
        setShowPageReloadAlert(false)
      }, 10000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [showPageReloadAlert, setShowPageReloadAlert])

  return bulkAssetUploadData.length > 0 ? (
    <div className="bulk-asset-upload-popup-container">
      <Grid.Container justify="flex-end">
        {showPageReloadAlert && (
          <>
            <Grid.Item xs={12} className="hc-pa-none">
              <Grid.Container justify="flex-end">
                <Grid.Item xs={4}>
                  <Card className="hc-pa-normal bulk-asset-upload-info-card">
                    <Caption
                      below={
                        <p className="hc-mb-normal">
                          There are asset upload&#40;s&#41; in progress,
                          <strong className="hc-fs-md"> please don't refresh/close the tab</strong>, else the asset's
                          creation process will be interupted.
                          <strong className="hc-fs-md">You can still continue to work in Gallery</strong>
                        </p>
                      }
                    >
                      <strong className="hc-fs-lg">{`Asset(s) uploads in progress`}</strong>
                    </Caption>
                    <Grid.Container justify="flex-end" align="center">
                      <Grid.Item className="hc-pt-expanded">
                        <Button
                          type="primary"
                          onClick={() => {
                            setShowPageReloadAlert(false)
                          }}
                        >
                          OK
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </Card>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container justify="flex-end" align="center">
                <Grid.Item className="hc-pv-none hc-mr-dense">
                  <EnterpriseIcon size="xl" icon={CaretDownIcon} className="hc-clr-grey05" />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </>
        )}
        <Grid.Item className="hc-pt-none">
          <Popover
            isVisible={showPopUp}
            onRequestClose={() => {
              setShowPopUp(false)
            }}
            content={
              <div>
                <Grid.Container className="hc-pb-normal">
                  <Grid.Item xs={9} tabIndex="-1">
                    <p className="hc-ph-normal">Below is the list of Bulk uploaded assets</p>
                  </Grid.Item>
                  <Grid.Item>
                    <Tooltip content={'Clear all messages'} location="top" className="hc-pr-normal">
                      <Button
                        iconOnly
                        type="ghost"
                        onClick={() => {
                          localStorage.setItem(BulkAssetUploadLsName, '[]')
                          setShowPopUp(false)
                          setLoadLSData(false)
                        }}
                      >
                        <EnterpriseIcon icon={MinusCircleIcon} className="hc-clr-error" />
                      </Button>
                    </Tooltip>
                  </Grid.Item>
                </Grid.Container>
                <List className="bulk-asset-upload-popup-list">
                  {bulkAssetUploadData.map((data: any) => (
                    <List.Item divider key={data.id}>
                      <BulkAssetUploadItem assetData={data} />
                    </List.Item>
                  ))}
                </List>
              </div>
            }
            location="top-right"
          >
            <div
              onClick={e => {
                e.stopPropagation()
                setShowPopUp(true)
                setShowPageReloadAlert(false)
              }}
              data-testid="bulk-asset-upload-icon"
              tabIndex={-1}
            >
              <div className={`${isAssetCreateInProgress ? 'pulse' : ''}`}>
                <EnterpriseIcon
                  size="xl"
                  icon={InProgressFilledIcon}
                  className={`bulk-asset-upload-icon ${isAssetCreateInProgress ? 'spin' : ''}`}
                />
              </div>
            </div>
          </Popover>
        </Grid.Item>
      </Grid.Container>
    </div>
  ) : (
    <></>
  )
}

export default BulkAssetUploadPopup
