import React, { createContext, useState, useEffect } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
// import 'canvas-ui-css-marquette'
import Header from 'components/Header/Header'
import ModalSetup from 'containers/ModalSetup'
import BulkAssetUploadPopup from './BulkAssetUploadPopup/BulkAssetUploadPopup'
import { bulkAssetProgressStatusesMap, BulkAssetUploadLsName } from './BulkAssetUploadPopup/Utils/constants'

export const BulkAssetDataContext = createContext<{
  loadLSData: boolean
  setLoadLSData: Function
  setShowPageReloadAlert: Function
}>({ loadLSData: false, setLoadLSData: () => {}, setShowPageReloadAlert: () => {} })

const BaseLayout: React.FC<{ children: any }> = ({ children }) => {
  const [loadLSData, setLoadLSData] = useState(true)
  const [showPageReloadAlert, setShowPageReloadAlert] = useState(false)
  const bulkAssetUploadData = JSON.parse(localStorage.getItem(BulkAssetUploadLsName) || '[]')
  useEffect(() => {
    if (!loadLSData) {
      setLoadLSData(true)
    }
  }, [loadLSData, setLoadLSData])
  useEffect(() => {
    const unloadCallback = (event: any) => {
      setShowPageReloadAlert(true)
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    if (
      bulkAssetUploadData.filter((data: any) => data.progress === bulkAssetProgressStatusesMap.INPROGRESS).length > 0
    ) {
      window.addEventListener('beforeunload', unloadCallback)
    }
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [bulkAssetUploadData])
  return (
    <BulkAssetDataContext.Provider value={{ loadLSData, setLoadLSData, setShowPageReloadAlert }}>
      <ModalSetup />
      <Layout darkMode={false} fullWidth theme="default">
        <Header />
        <Layout.Body includeRail={true}>{children}</Layout.Body>
      </Layout>
      <BulkAssetUploadPopup showPageReloadAlert={showPageReloadAlert} setShowPageReloadAlert={setShowPageReloadAlert} />
    </BulkAssetDataContext.Provider>
  )
}

export default BaseLayout
