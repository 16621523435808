import { cloneDeep } from 'lodash'
import { BulkAssetUploadLsName } from './constants'

const removeBulkUploadMessageFromLS = (uuid: string) => {
  const bulkAssetUploadData = JSON.parse(localStorage.getItem(BulkAssetUploadLsName) || '[]')
  const newBulkAssetUploadData = cloneDeep(bulkAssetUploadData || []).filter((data: any) => data.id !== uuid)
  localStorage.setItem(BulkAssetUploadLsName, JSON.stringify(newBulkAssetUploadData))
}

export default removeBulkUploadMessageFromLS
