import { CheckCircleFilledIcon, ErrorFilledIcon, InProgressFilledIcon } from '@enterprise-ui/icons'
import { bulkAssetProgressStatusesMap } from './constants'

const getStatusIconAndColor = (status: string) => {
  switch (status) {
    case bulkAssetProgressStatusesMap.COMPLETED:
      return {
        icon: CheckCircleFilledIcon,
        color: 'hc-clr-success',
      }
    case bulkAssetProgressStatusesMap.FAILED:
      return {
        icon: ErrorFilledIcon,
        color: 'hc-clr-error',
      }
    default:
      return {
        icon: InProgressFilledIcon,
        color: 'hc-clr-interactive',
      }
  }
}

export default getStatusIconAndColor
