import React from 'react'
import { cloneDeep, some } from 'lodash'
import { IAssetTaxonomy, ICreateAssetFileValues } from 'store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TaxonomySelector from 'components/taxonomy/TaxonomySelector'
import AssetPublicPrivate from 'components/assetPublicPrivate/AssetPublicPrivate'
import { Button, Card, Form, Grid, Input, Spinner, Table } from '@enterprise-ui/canvas-ui-react'

interface CreateAssetFormProps {
  index: number
  formik: any
  handleNameChange: Function
  removeFile: Function
  isBulkcategory: boolean
  category: string | null | undefined
  makeAssetPublic: Function
  setSelectedTaxonomy: Function
  newFilesStatusValues: ICreateAssetFileValues[]
  handleMultipartUpload: (e: any) => void
  setSelectedTaxonomyTouched: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateAssetForm: React.FC<CreateAssetFormProps> = ({
  index,
  formik,
  category,
  removeFile,
  isBulkcategory,
  handleNameChange,
  makeAssetPublic,
  setSelectedTaxonomy,
  newFilesStatusValues,
  handleMultipartUpload,
  setSelectedTaxonomyTouched,
}) => {
  const key = formik.values.id || index
  return (
    <Card key={`${key}-card`} elevation={1} className="hc-pa-dense">
      <Grid.Container direction="column">
        <Grid.Item className="hc-pb-none" xs={12}>
          <Grid.Container direction="row" className="hc-pa-normal">
            <Grid.Item xs={6}>
              <Grid.Container direction="column" spacing={'dense'}>
                <Grid.Item xs={12} className="hc-pb-normal hc-mb-normal">
                  <Form.Field
                    key={`create-asset-name-input-${key}`}
                    id={`create-asset-name-input-${key}`}
                    error={!!formik.errors.name}
                    errorText={formik.errors.name}
                    type="text"
                    name="name"
                    placeholder="Asset name"
                    label="Asset name*"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameChange(e, index)}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    data-testid={`create-asset-name-input-${key}`}
                    className="hc-mb-dense"
                  />
                </Grid.Item>
                {!isBulkcategory && (
                  <Grid.Item xs={12} className="hc-pt-none">
                    <TaxonomySelector
                      key={`taxonomy-selector-${key}`}
                      setSelectedTaxonomy={taxonomy => setSelectedTaxonomy(taxonomy, index)}
                      setTaxonomyTouched={() => {
                        formik.setFieldTouched('assetTaxonomy', true)
                      }}
                      setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
                      defaultSelections={
                        category ? [{ name: category, id: 0 } as unknown as IAssetTaxonomy] : undefined
                      }
                      taxonomySelected={formik.values.taxonomyId}
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Grid.Container direction="column">
                <Grid.Item xs={12}>
                  <Input.Label
                    error={!!(formik.touched.files && formik.errors.files)}
                    className="hc-mb-none hc-pb-sm bold-asset-text"
                  >
                    Files being added ({formik.values.files.length})*
                  </Input.Label>
                  <Grid.Container direction="column">
                    {some(formik.values.files) && (
                      <Grid.Item xs={12} className="hc-pt-dense">
                        <div className="edit-asset-fileList" data-testid="create-asset-file-list">
                          <Table>
                            <Table.Body>
                              {formik.values.files.map(
                                (file: { name: React.Key | null | undefined }, fileIndex: number) => (
                                  <Table.Row key={file.name} align="center">
                                    <Table.Data xs={9} className="hc-pl-none" data-testid="file-ready-for-upload">
                                      {file.name}
                                    </Table.Data>
                                    <Table.Data xs={3} className="hc-ta-right">
                                      {!newFilesStatusValues.find(fileValue => fileValue.name === file.name) && (
                                        <Button
                                          iconOnly
                                          size="dense"
                                          data-testid="remove-file-button"
                                          onClick={() => {
                                            const newFiles = cloneDeep(formik.values.files)
                                            newFiles.splice(fileIndex, 1)
                                            removeFile(newFiles, index)
                                          }}
                                        >
                                          <FontAwesomeIcon icon="trash" className="trash-icon" />
                                        </Button>
                                      )}

                                      {newFilesStatusValues.find(
                                        fileValue => fileValue.name === file.name && fileValue.status === 'UPLOADING'
                                      ) && <Spinner size="dense" />}
                                      {newFilesStatusValues.find(
                                        fileValue => fileValue.name === file.name && fileValue.status === 'UPLOADED'
                                      ) && <FontAwesomeIcon icon="check" color="green" size="lg" />}
                                      {newFilesStatusValues.find(
                                        fileValue => fileValue.name === file.name && fileValue.status === 'QUEUED'
                                      ) && <FontAwesomeIcon icon="clock" size="lg" />}
                                    </Table.Data>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </Grid.Item>
                    )}
                    <Grid.Item xs={12} className="hc-pt-dense container-drop">
                      <Input.DropArea
                        key={`create-asset-file-upload-${key}`}
                        id={`create-asset-file-upload-${key}`}
                        data-testid={`create-asset-file-upload-${key}`}
                        expanded
                        multiple
                        fullwidth
                        instructionText={'Drop files or click here to upload'}
                        onUpdate={(e: any) => handleMultipartUpload(e)}
                      />
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <AssetPublicPrivate
                        index={index}
                        makeAssetPublic={(isAssetPublic: boolean) => makeAssetPublic(isAssetPublic, index)}
                        assetPublic={formik.values.isAssetPublic}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}

export default CreateAssetForm
