import React, { useEffect, useState } from 'react'
import { ExpandableSection, Grid, Divider, Heading, Anchor, Table } from '@enterprise-ui/canvas-ui-react'
import { some, find } from 'lodash'
import { TagBaseView } from 'gallerydigitalassets-v1-client'
import { convertCamelToSentence, convertDetailValue, capitalizeAcronymInSentence } from 'utils/string'
import { useEnv } from '@praxis/component-runtime-env'
import styles from './DetailDrawer.module.scss'

type DetailDrawerProps = {
  openDrawer: boolean
  forceRender: boolean
  tags: TagBaseView[]
  title: string
  startExpanded: boolean
}

const DetailDrawer: React.FC<DetailDrawerProps> = ({ openDrawer, tags, title, forceRender, startExpanded }) => {
  const [toggleDrawer, setToggleDrawer] = useState(startExpanded)
  const [showMoreToggle, setShowMoreToggle] = useState(false)
  const [detailTags, setDetailTags] = useState<TagBaseView[]>()
  const [enableShowMore, setEnableShowMore] = useState(false)
  const { assetDetailKeysToClean, sparkResourceId, sparkServer } = useEnv()

  // overlap is 18 , CSS is working wierd so work around for manually sizing max height
  // of expandable section when closed and when opened before show more is clicked
  const drawerHeight = {
    maxHeight: showMoreToggle ? 'none' : toggleDrawer ? '100%' : '70px',
  }

  useEffect(() => {
    setToggleDrawer(openDrawer)
  }, [openDrawer, forceRender])

  useEffect(() => {
    setToggleDrawer(startExpanded)
  }, [startExpanded])

  useEffect(() => {
    setShowMoreToggle(openDrawer)
  }, [openDrawer])

  useEffect(() => {
    const reducedTags = groupTagsByKey(tags)
    if (showMoreToggle) {
      setDetailTags(reducedTags)
    } else {
      if (tags !== undefined) {
        setDetailTags(reducedTags.slice(0, 5))
      } else {
        setDetailTags([])
      }
    }
  }, [showMoreToggle])

  useEffect(() => {
    if (tags !== undefined && tags.length > 5) {
      setEnableShowMore(true)
    } else {
      setEnableShowMore(false)
    }
  }, [tags])

  const groupTagsByKey = (tags: TagBaseView[]): TagBaseView[] => {
    return tags?.reduce((reducedTags: TagBaseView[], tag: TagBaseView) => {
      const key = tag.key
      const tagIndxFound = reducedTags.findIndex(reducedTag => reducedTag.key === key)
      if (tagIndxFound < 0) {
        reducedTags.push(tag)
      } else {
        const newTag = new TagBaseView()
        newTag.id = tag.id
        newTag.value = reducedTags[tagIndxFound].value + ',' + tag.value
        newTag.key = tag.key
        reducedTags[tagIndxFound] = newTag
      }
      return reducedTags
    }, [])
  }

  return (
    <Grid.Container direction="column" spacing="none">
      <ExpandableSection
        data-testid={`btn-expand expanded-${toggleDrawer}`}
        padding="none"
        style={drawerHeight}
        expanded={toggleDrawer}
        onExpand={() => {
          setToggleDrawer(!toggleDrawer)
        }}
      >
        <Grid.Item className="relative-item">
          <Grid.Container direction="column" spacing="dense" className="absolute-container">
            <Grid.Item xs={6} className="hc-pa-none hc-mt-lg hc-ml-dense">
              <Heading size={5}>{convertCamelToSentence(title)}</Heading>
            </Grid.Item>
            <Grid.Item xs={12} className={`hc-pt-none hc-pl-none hc-mt-dense hc-bg-grey04 ${styles['tag-divider']}`}>
              <Divider className="hc-bg-grey04" width={1} />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <ExpandableSection.Content>
          {some(detailTags) && (
            <Grid.Item
              data-testid={`show-more-toggle-${showMoreToggle}`}
              className="hc-pl-none hc-pt-none hc-pb-none hc-pr-none hc-mv-none"
            >
              <Table name="Detail Table">
                <Table.Body>
                  {detailTags!!.map((tag: TagBaseView, i) => (
                    <Table.Row data-testid="tags">
                      <Table.Data xs={6} className="hc-pl-none">
                        {capitalizeAcronymInSentence(convertDetailValue(tag.key))}
                      </Table.Data>
                      <Table.Data xs={6} className={`${styles['tag-value-text']}`}>
                        {find(assetDetailKeysToClean, function (key) {
                          return tag.key === key
                        }) ? (
                          convertDetailValue(tag.value)
                        ) : tag.key.toLowerCase() === sparkResourceId.toLowerCase() ? (
                          <a
                            className={`${styles['hyperlink']}`}
                            data-testid={`hyperlink-${tag.value.toUpperCase()}`}
                            href={`${sparkServer}${tag.value.toUpperCase()}`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {tag.value.toUpperCase()}
                          </a>
                        ) : (
                          tag.value
                        )}
                      </Table.Data>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {enableShowMore && (
                <Grid.Container spacing="none" justify="flex-end" className="hc-pr-normal hc-pt-xs">
                  <Anchor
                    data-testid="btn-show-more-toggle"
                    style={{ zIndex: 1 }}
                    onClick={() => setShowMoreToggle(!showMoreToggle)}
                  >
                    <span>{!showMoreToggle ? 'Show more' : 'Show less'}</span>
                  </Anchor>
                </Grid.Container>
              )}
            </Grid.Item>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </Grid.Container>
  )
}
export default DetailDrawer
