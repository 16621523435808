import React, { useState, useEffect } from 'react'
import { IAssetTaxonomy, useTaxonomy } from 'store'
import { Input, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
import { upperFirst, lowerCase, kebabCase } from 'lodash'
import { useEnv } from '@praxis/component-runtime-env'
import { findTaxonomyPath } from './TaxonomyUtils'

interface ITaxonomySelectorProps {
  key: string
  setSelectedTaxonomy: (taxonomy: string) => any
  setTaxonomyTouched: () => void
  setSelectedTaxonomyTouched: (touched: boolean) => any
  defaultSelections?: IAssetTaxonomy[]
  taxonomySelected?: string
}

interface ISelectorProps {
  setTaxonomyTouched: () => void
  setSelectedTaxonomy: (tax: string) => void
  taxonomy: TaxonomyNodeView
  index: number
  labels: string[]
  preselections?: IAssetTaxonomy[]
  setSelectedTaxonomyTouched: (touched: boolean) => any
  taxonomyTree: string[] | null
}

const Selector: React.FC<ISelectorProps> = ({
  taxonomy,
  index,
  labels,
  preselections,
  setSelectedTaxonomyTouched,
  setTaxonomyTouched,
  setSelectedTaxonomy,
  taxonomyTree,
}) => {
  const { children } = taxonomy
  const [selection, setSelection] = useState<TaxonomyNodeView>()
  const [preselection, setPreselection] = useState<string | undefined>(
    taxonomyTree && taxonomyTree.length ? taxonomyTree[0] : ''
  )
  const name = labels && labels[index] ? labels[index] : `TAXONOMY NODE - ${index}`
  const label = upperFirst(lowerCase(name)).replace(' ', '')

  useEffect(() => {
    if (preselections && preselections.length > 0 && preselections[index] && children && children.length > 0) {
      const preselect = children?.find(child => child.name.toLowerCase() === preselections[index].name.toLowerCase())
      if (preselect) {
        setPreselection(preselect.id)
        setSelection(preselect)
        if (preselect.children?.length === 0) {
          setSelectedTaxonomy(preselect.id)
        }
      }
    }
  }, [])
  useEffect(() => {
    if (preselection) {
      setSelection(undefined)
      const preselectionExists = children?.find(child => child.id === preselection)
      if (!preselectionExists) {
        setPreselection(undefined)
      }
    }
  }, [taxonomy])

  return (
    <>
      <Grid.Item xs={12} className="hc-pt-none">
        <Input.Label>{label}*</Input.Label>
        <Input.Select
          data-testid={`edit-asset-taxonomy-${kebabCase(name)}`}
          value={preselection ? preselection : ''}
          error={!!(!preselection && children!.length > 0)}
          options={children?.map(child => ({ label: child.name, value: child.id }))}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newTaxonomies = children ? children[e.target.value as unknown as number] : undefined
            setPreselection(newTaxonomies?.id)
            setSelectedTaxonomyTouched(true)
            setTaxonomyTouched()
            setSelection(undefined)
            if (newTaxonomies) {
              if (newTaxonomies!.children!.length > 0) {
                setSelection(newTaxonomies)
                setSelectedTaxonomy('')
              } else {
                setSelectedTaxonomy(newTaxonomies.id)
              }
            }
          }}
        />
      </Grid.Item>
      {selection && selection.children && selection.children.length > 0 && (
        <Selector
          setSelectedTaxonomy={setSelectedTaxonomy}
          setTaxonomyTouched={setTaxonomyTouched}
          setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
          taxonomy={selection}
          index={index + 1}
          labels={labels}
          preselections={preselections}
          taxonomyTree={taxonomyTree && taxonomyTree.length ? taxonomyTree.slice(1) : null}
        />
      )}
    </>
  )
}

const TaxonomySelector: React.FC<ITaxonomySelectorProps> = ({
  key,
  defaultSelections,
  setSelectedTaxonomy: selectTaxonomy,
  setSelectedTaxonomyTouched,
  setTaxonomyTouched,
  taxonomySelected,
}) => {
  const { taxonomies, getCategory } = useTaxonomy()
  const { taxonomyLabels } = useEnv()
  const [selectedTaxonomies, setSelectedTaxonomy] = useState<TaxonomyNodeView>()
  const [taxonomyTree, setTaxonomyTree] = useState<string[] | null>([])

  const getAssetTaxonomy = async () => {
    const cat = (await getCategory('Asset')) as TaxonomyNodeView
    setSelectedTaxonomy(cat)
  }

  useEffect(() => {
    getAssetTaxonomy()
  }, [taxonomies])

  useEffect(() => {
    if (selectedTaxonomies) {
      // Useful to figure out the parents of the selected taxonomy and create an array of the taxonomIds from parent to child [0...n]
      const taxonomyPath = findTaxonomyPath(selectedTaxonomies, taxonomySelected)
      setTaxonomyTree(taxonomyPath)
    }
  }, [taxonomySelected])

  return (
    <Grid.Container direction="column">
      {!selectedTaxonomies && <Spinner size="dense" />}
      {selectedTaxonomies && (
        <Selector
          key={key}
          setSelectedTaxonomy={selectTaxonomy}
          setTaxonomyTouched={setTaxonomyTouched}
          setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
          taxonomy={selectedTaxonomies}
          index={0}
          labels={taxonomyLabels}
          preselections={defaultSelections}
          taxonomyTree={taxonomyTree}
        />
      )}
    </Grid.Container>
  )
}

export default TaxonomySelector
