import { useFormik } from 'formik'
import { useState } from 'react'
import { cloneDeep } from 'lodash'
import { match } from 'utils/validation'
import { ICreateAssetFileValues } from 'store'
import { v4 as uuidv4 } from 'uuid'
import { useEnv } from '@praxis/component-runtime-env'
import { ICreateAssetValues } from 'components/modals/CreateAssetModal/BulkCreateAssetModal'

const useFormikInstances = () => {
  const initialValues: ICreateAssetValues = {
    id: uuidv4(),
    name: '',
    files: [],
    taxonomyId: '',
    isAssetPublic: true,
  }
  const formikInstance = useFormik({
    initialValues,
    onSubmit: async () => {},
  })
  const { nameMatcher } = useEnv()
  const initializeFormik = () => {
    return formikInstance
  }
  const [formikInstances, setFormikInstances] = useState<any[]>([formikInstance])

  const checkValidity = (updatedFormikInstances: any[], deleteInstanceFlag?: boolean) => {
    updatedFormikInstances.forEach(formikInstance => {
      if (!formikInstance.values.name.trim()) {
        formikInstance.errors.name = 'Required'
      } else if (!match(formikInstance.values.name, nameMatcher.expression)) {
        formikInstance.errors.name = nameMatcher.message
      } else {
        delete formikInstance.errors.name
      }
      if (formikInstance.values.files.length <= 0) {
        formikInstance.errors.files = 'Required'
      } else {
        delete formikInstance.errors.files
      }
      if (!formikInstance.values.taxonomyId.trim()) {
        formikInstance.errors.taxonomyId = 'Required'
      } else {
        delete formikInstance.errors.taxonomyId
      }
      formikInstance.dirty = true
    })
    setFormikInstances(updatedFormikInstances)
  }

  const removeFiles = (index: number, newFiles: ICreateAssetFileValues[]) => {
    const updatedFormikInstances = cloneDeep(formikInstances)
    updatedFormikInstances[index]['values']['files'] = newFiles
    checkValidity(updatedFormikInstances)
  }

  const addFormikInstance = (index: number, bulkTaxonomyId?: string) => {
    const newInstances = cloneDeep(formikInstances)
    newInstances.splice(index + 1, 0, initializeFormik())
    newInstances[index + 1].values.id = uuidv4()
    if (bulkTaxonomyId) {
      newInstances[index + 1].values.taxonomyId = bulkTaxonomyId
    }
    checkValidity(newInstances)
  }

  const updateFormikInstance = (index: number, field: string, value: any) => {
    const updatedFormikInstances = cloneDeep(formikInstances)
    updatedFormikInstances[index]['values'][field] = value
    checkValidity(updatedFormikInstances)
  }

  const updateAllFormikInstances = (field: string, value: any) => {
    const updatedFormikInstances = cloneDeep(formikInstances)
    updatedFormikInstances.forEach(formikInstance => {
      formikInstance.values[field] = value
    })
    checkValidity(updatedFormikInstances)
  }

  const deleteFormikInstance = (index: number) => {
    const updatedFormikInstances = formikInstances.filter((_, i) => i !== index)
    checkValidity(updatedFormikInstances, true)
  }

  return [
    formikInstances,
    addFormikInstance,
    updateFormikInstance,
    updateAllFormikInstances,
    deleteFormikInstance,
    removeFiles,
  ] as const
}

export default useFormikInstances
