import React, { useContext, useEffect, useState } from 'react'
import './CreateAssetModal.scss'
import { get, some } from 'lodash'
import { useFileUpload } from 'hooks'
import { useFormikInstances } from 'hooks'
import CreateAssetForm from './CreateAssetForm'
import { StringParam, useQueryParam } from 'use-query-params'
import TaxonomySelector from 'components/taxonomy/TaxonomySelector'
import { useAsset, ICreateAssetFileValues, IAssetTaxonomy } from 'store'
import EnterpriseIcon, { MinusCircleIcon, PlusCircleIcon } from '@enterprise-ui/icons'
import { Modal, Grid, Button, Spinner, Tooltip, Input, Card } from '@enterprise-ui/canvas-ui-react'
import { BulkAssetDataContext } from 'containers/BaseLayout/BaseLayout'
import bulkAssetUploadOnSave from './bulkAssetUploadOnSave'

export interface ICreateAssetValues {
  id: string
  name: string
  files: ICreateAssetFileValues[]
  taxonomyId: string
  isAssetPublic: boolean
}

const BulkCreateAssetModal = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [numberOfEntries, setNumberOfEntries] = useState<number>(1)
  const [isBulkCategory, setIsBulkCategory] = useState<boolean>(false)
  const [bulkTaxonomyId, setBulkTaxonomyId] = useState<string>('')
  const [, setBulkTaxonomyTouched] = useState<boolean>(false)
  const [, setSelectedTaxonomyTouched] = useState<boolean>(false)
  const [category] = useQueryParam('category', StringParam)
  const { isFileDenyListed, validateFileNames } = useFileUpload()
  const { setLoadLSData, setShowPageReloadAlert } = useContext(BulkAssetDataContext)

  const { newFilesStatusValues } = useAsset()
  const [
    formikInstances,
    addFormikInstance,
    updateFormikInstance,
    updateAllFormikInstances,
    deleteFormikInstance,
    removeFiles,
  ] = useFormikInstances()

  useEffect(() => {
    if (isBulkCategory) {
      updateAllFormikInstances('taxonomyId', bulkTaxonomyId)
    }
  }, [bulkTaxonomyId])

  const onSubmit = async () => {
    const valuesArray = formikInstances.map(formik => formik.values)
    setIsSubmitting(true)
    try {
      bulkAssetUploadOnSave(
        valuesArray.map(data => ({
          name: data.name,
          files: data.files,
          isAssetPublic: data.isAssetPublic,
          taxonomyId: data.taxonomyId,
        })),
        setShowPageReloadAlert,
        setLoadLSData
      )
      props.closeModal()
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const handleMultipartUpload = async (e: any, formikIndex: number) => {
    const files = get(e, 'dataTransfer.files') || get(e, 'target.files')

    let assetFiles: ICreateAssetFileValues[] = Object.keys(files).map((key: string) => ({
      name: files[key].name,
      file: files[key],
      status: 'QUEUED',
    }))

    const denyListedFileTypesExist = await isFileDenyListed(
      assetFiles.map((assetFile: ICreateAssetFileValues) => {
        return assetFile.name
      })
    )

    const invalidFileNames = await validateFileNames(assetFiles.map(file => file.name))

    if (some(assetFiles) && !denyListedFileTypesExist && !some(invalidFileNames)) {
      updateFormikInstance(formikIndex, 'files', [...formikInstances[formikIndex].values.files, ...assetFiles])
    }
  }

  const removeFile = (newFiles: ICreateAssetFileValues[], formikIndex: number) => {
    removeFiles(formikIndex, newFiles)
  }

  const onAddEntry = (formikIndex: number) => {
    setNumberOfEntries(numberOfEntries + 1)
    isBulkCategory ? addFormikInstance(formikIndex, bulkTaxonomyId) : addFormikInstance(formikIndex)
  }

  const onDeleteEntry = (formikIndex: number) => {
    setNumberOfEntries(numberOfEntries - 1)
    deleteFormikInstance(formikIndex)
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    updateFormikInstance(index, 'name', e.target.value)
  }

  const makeAssetPublic = (isAssetPublic: boolean, formikIndex: number) => {
    formikInstances.forEach((formik, index) => {
      updateFormikInstance(formikIndex, 'isAssetPublic', isAssetPublic)
    })
  }

  const setSelectedTaxonomy = (taxonomyId: string, formikIndex: number) => {
    formikInstances.forEach((formik, index) => {
      updateFormikInstance(formikIndex, 'taxonomyId', taxonomyId)
    })
  }

  const handleBulkAssetCategoryToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateAllFormikInstances('taxonomyId', '')
    setIsBulkCategory(e.target.checked)
  }

  return (
    <Modal className="modal-parent-container" isVisible onRefuse={() => props.closeModal()} onApproveModal={() => {}}>
      <div className="hc-pa-normal">
        <Grid.Container direction="row" align="flex-end">
          <Grid.Item xs={2}>
            <h1 className="hc-fs-lg hc-pl-sm hc-pt-sm">Add Asset</h1>
          </Grid.Item>
          <Grid.Item xs={5}>
            <Input.Toggle
              id="bulk-asset-category"
              label="Use same category for all assets"
              onChange={handleBulkAssetCategoryToggle}
            />
          </Grid.Item>
        </Grid.Container>
      </div>
      <div className="hc-pa-normal">
        <form
          onSubmit={e => {
            e.preventDefault()
            formikInstances.forEach(formik => formik.submitForm())
          }}
        >
          {isBulkCategory && (
            <Grid.Item xs={12} className="hc-mb-normal">
              <Card elevation={1} className="hc-pa-md">
                <TaxonomySelector
                  key={`taxonomy-selector-bulk`}
                  setSelectedTaxonomy={taxonomy => setBulkTaxonomyId(taxonomy)}
                  setTaxonomyTouched={() => {
                    setBulkTaxonomyTouched(true)
                  }}
                  setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
                  defaultSelections={category ? [{ name: category, id: 0 } as unknown as IAssetTaxonomy] : undefined}
                />
              </Card>
            </Grid.Item>
          )}
          {formikInstances.map((formik, index) => (
            <Grid.Container direction="row" key={formik.values.id} align="center">
              <Grid.Item xs={11}>
                <CreateAssetForm
                  index={index}
                  formik={formik}
                  category={category}
                  removeFile={removeFile}
                  isBulkcategory={isBulkCategory}
                  handleNameChange={handleNameChange}
                  makeAssetPublic={makeAssetPublic}
                  setSelectedTaxonomy={setSelectedTaxonomy}
                  newFilesStatusValues={newFilesStatusValues}
                  setSelectedTaxonomyTouched={setSelectedTaxonomyTouched}
                  handleMultipartUpload={e => handleMultipartUpload(e, index)}
                />
              </Grid.Item>
              <Grid.Item xs={1}>
                <Tooltip id="tooltip-add" location="top-right" tooltip="Add another entry">
                  <Button
                    iconOnly
                    aria-label="Add another entry"
                    className="hc-clr-success"
                    onClick={() => onAddEntry(index)}
                    data-testid={`bulkAsset-addButton-${formik.values.id}`}
                    isEnterpriseIcon
                  >
                    <EnterpriseIcon icon={PlusCircleIcon} expanded />
                  </Button>
                </Tooltip>
                <Tooltip id="tooltip-minus" location="top-right" tooltip="Delete this entry" className="hc-pt-dense">
                  <Button
                    iconOnly
                    aria-label="Delete this entry"
                    className="hc-clr-error"
                    onClick={() => onDeleteEntry(index)}
                    data-testid={`bulkAsset-deleteButton-${formik.values.id}`}
                    disabled={formikInstances.length === 1}
                    isEnterpriseIcon
                  >
                    <EnterpriseIcon icon={MinusCircleIcon} expanded />
                  </Button>
                </Tooltip>
              </Grid.Item>
            </Grid.Container>
          ))}
          <Grid.Item xs={12} className="hc-pt-none">
            <Grid.Container direction="row" justify="flex-end" align="center" className="hc-pa-normal">
              <Button type="secondary" data-testid="create-asset-cancel" onClick={() => props.closeModal()}>
                Cancel
              </Button>
              <Button
                type="primary"
                className="submit-button"
                onClick={() => onSubmit()}
                disabled={
                  isSubmitting ||
                  !formikInstances.every(formik => Object.keys(formik.errors).length === 0 && formik.dirty)
                }
                data-testid="create-asset-submit"
              >
                {isSubmitting ? <Spinner size="dense" /> : 'Save'}
              </Button>
            </Grid.Container>
          </Grid.Item>
        </form>
      </div>
    </Modal>
  )
}

export default BulkCreateAssetModal
